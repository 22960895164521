.passsword {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.passsword input {
    width: 49%;
}
.signUp-form {
    max-width: 600px;
    background: #F8FAFC;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 30px;
    padding: 30px;
}
.signUp-form h3 {
    font-family: 'Helvetica', sans-serif;
    font-size: 40px;
    font-weight: 750;
    text-align: center;
    color: #13004C;
}
.signUp-form p {
    font-family: 'Helvetica', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.signUp-form img {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.signUp-form label[for="email"] {
    margin-top: 25px;
}
.signUp-form label {
    margin-top: 15px;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: left;

}
.signUp-form input, .signUp-form select {
    border: 1px solid #0F1519;
    outline: none;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    background: #0031DF12;
    color: #000;
    height: 40px;
}
.signUp-form .forgot, .signUp-form .sign-in {
    outline: none;
    color: #13004C;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 5px;
}
.signUp-form .forgot {
    color: #000;
    margin-top: 15px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}
.signUp-form .submit {
    background: linear-gradient(278.83deg, #551190 5.52%, #261473 92.93%);
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}
input[type="checkbox"] {
    position: relative;
    top: 13px;
    margin-top: 0 !important;
}
.notValid {
    border: 1px solid red !important;
}
@media(max-width: 576px) {
    .signUp-form{
        width: 90%;
    }
    .signUp-form h3 {
        font-size: 25px;
    }
    .signUp-form p {
        font-size: 12px;
    }
    .container-hero h3 {
        font-size: 25px;
    }
    .container-hero p {
        font-size: 20px;
    }
    .signUp-form label {
        font-size: 12px;
    }
    .signUp-form button {
        font-size: 12px;
    }
    input[type="checkbox"] {
        width: 12px !important;
        height: 12px !important;
        top: 1px !important;
    }
}
