@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.terms {
    background: #F9FAFB;
}
.container-terms {
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 20px;
    height: 100%;
    width: 100%;
    padding-bottom: 20px;
} 
.container-terms h1 {
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 64px;
    font-weight: 700;
    color: #1D1D1F;
}
.container-terms p {
   font-family: 'Inter', sans-serif;
   font-size: 20px;
   font-style: italic;
   font-weight: 400;
   text-align: left;
   color: #1D1D1F;
   margin-top: 5px;
}
.container-terms h3 {
   font-family: 'Inter', sans-serif;
   font-size: 21px;
   font-weight: 700;
   text-align: left;
   margin-top: 10px;
}

@media(max-width: 768px) {
    .container-terms {
        padding-left: 20px;
        padding-right: 20px;
    }
    .container-terms h1 {
        font-size: 35px;
    }
    .container-terms h3 {
        font-size: 16px;
    }
    .container-terms p {
        font-size: 15px;
    }
}