@import url('https://fonts.googleapis.com/css2?family=Inter&family=Plus+Jakarta+Sans:wght@700&display=swap');
.container-forgot {
    width: 100%;
    height: 100vh;
    position: fixed !important;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 110;
    display: none;
}

.container-forgot.active {
    display: flex;
}

.container-forgot h3 {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #2b3674;
}
.container-forgot p {
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    color: #000;
    font-size: 16px;
}
