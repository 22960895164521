.page {
   position: absolute;
   top: 50px;
   left: 0;
   z-index: 150 !important;
   display: none;
   min-height: 100vh;
}
.page.active {
    display: flex;
}
.container-plans-page .cards {
    width: 100%;
}
.container-plans-page .cards .card {
    width: 300px;
}
.plans-container .cards {
    background: #3B1F68;
    box-shadow: 0px 0px 20px 0px #00000059;
    border-radius: 30px;
    width: 80%;
    padding: 40px;
}