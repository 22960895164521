header {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    height: 80px;
    z-index: 100;
}
header .button-container {
    position: absolute;
    top: 50%;
    right: 80px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}
header .button-container select {
    margin-right: 40px;
}
.login {
    border-radius: 17px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 1px solid #868CFF;
    min-width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    background: none;
    outline: none;
    cursor: pointer;
}
.login i {
    font-size: 16px;
    margin-right: 5px;
}
@media(max-width: 576px) {
   header {
    padding: 20px;
   }
   header .button-container {
    position: initial;
    top: 0;
    right: 0;
    transform: initial;
}
.logo {
    width: 80%;
}
}