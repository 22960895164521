.footer-container {
    width: 100%;
    height: 360px;
    background: radial-gradient(103.78% 100% at 50% 0%, rgba(118, 146, 255, 0) 80.55%, rgba(122, 150, 255, 0.04) 100%),
radial-gradient(120.05% 100% at 50% 0%, rgba(226, 232, 255, 0) 33.78%, rgba(226, 232, 255, 0.08) 100%),
linear-gradient(0deg, rgba(226, 232, 255, 0.01), rgba(226, 232, 255, 0.01));
    box-shadow: 0px -21px 63px -18px #E2E8FF1F inset;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.footer-content-right {
    display: flex;
    width: 50%;
    align-items: flex-start;
    justify-content: space-around;
    z-index: 12;
}
.footer-content-right nav {
    font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 11px;
   font-weight: 500;
   text-align: left;
   color: #A8B8D8;
}

.footer-content-right nav ul {
    margin-top: 15px;
}

.footer-content-right nav ul li {
    list-style: none;
    margin-top: 10px;
}
.footer-content-right nav ul li .nav-item {
    text-decoration: none;
    font-family: 'DM Sans', sans-serif;
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    color: #DEE2E6;
}
.footer-content-left {
    width: 17%;
    z-index: 12;
}

.footer-content-left p {
    font-family: 'DM Sans', sans-serif;
    font-size: 11px;
    font-weight: 500;
    text-align: left;
    color: #8392AB;
    margin-top: 15px;
}
.socials-footer {
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.socials-footer .social {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid #252F40;
    display: flex;
    align-items: center;
    justify-content: center;
}
.copyright {
    width: 100%;
    border-top: 1px solid #252F40;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    height: 40px;
}
.copyright p {
    margin-top: 50px;
    color: #CBD5E0;
    font-family: 'DM Sans', sans-serif;
    font-size: 11px;
    font-weight: 500;
    text-align: center;

}
.footerDonut {
    position: absolute;
    bottom: 0;
    left: 0;
}

.groupFooter {
    position: absolute;
    bottom: 0;
    left: 25%;
    height: 100%;
}
@media(max-width: 576px) {
    .groupFooter {
        width: 200px;
        height: 200px;
        top: 0;
        left: 0;
        transform: rotate(180deg);
    }
    .footerDonut {
        width: 240px;
        height: 200px;
    }
    .home-footer {
        flex-direction: column;
        justify-content: left;
        height: auto;
        align-items: flex-start;
        padding: 20px;
    }
    .footer-content-right {
        width: 100%;
        flex-direction: column;
        justify-content: initial;
        margin-top: 20px;
    }
    .footer-content-left {
        width: 50%;
        margin-top: 30px;
    }
    .footer-content-right nav {
        margin-top: 20px;
    }
    .copyright {
        margin-top: 15px;
    }
    .copyright p {
        margin-top: 0px;
    }
    .footer-container {
        height: auto;
        padding: 20px;
        align-items: flex-start;
        flex-direction: column;
    }
}