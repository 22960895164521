.custom-dropdown-import {
  position: relative;
  display: inline-block;
  min-width: 140px;
  padding: 14px;
}
.custom-dropdown-import i:nth-child(1) {
  margin-right: 5px;
}
.custom-dropdown-import i:nth-child(2) {
  margin-left: 5px;
}
.dropdown-header-import {
  cursor: pointer;
  padding: 8px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 4px 14px 0px #00000017;
  font-family: 'Helvetica', sans-serif;
  font-size: 10px;
  font-weight: 750;
  text-align: left;
}

.dropdown-header-import:nth-child(3){
  font-size: 15px;
}
.dropdown-header-import  .fa-shopify{
  color: #00FF00;
  font-size: 14px;
}
.dropdown-options-import {
  list-style: none;
  padding: 5px;
  margin: 0;
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 80%;
  z-index: 100;
}
.userdrop {
  top: 45px !important;
}
.dropdown-options-import li {
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #333;
  font-family: 'Helvetica', sans-serif;
  font-size: 9px;
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid #8A64E41A;
}


.dropdown-options-import li:hover {
  background-color: #f0f0f0;
}
.add-shop {
  display: block;
  text-decoration: none;
  margin: 5px auto !important;
  background: #fff;
  width: 90% !important;
  padding: 4px;
  border-radius: 6px;
  color: #333;
  font-family: 'Helvetica', sans-serif;
  font-size: 7px;
  font-weight: 400;
  text-align: center;
  box-shadow: 1.2306941747665405px 1.2306941747665405px 3.692082405090332px 0px #0000001A;
}
.dropdown-options-import li a {
  text-decoration: none;
  color: #000;
}
.dropdown-options-import li:hover {
  color: #8A64E4 !important;
}

.dropdown-options-import li a:hover {
  color: #8A64E4;
}
@media(max-width: 768px) {
  .custom-dropdown-import {
    min-width: 70%;
    order: 2;
  }
  .dropdown-options-import {
    top: 50px;
    width: 85%;
}
.dropdown-header-import {
  justify-content: space-between;
}
}
